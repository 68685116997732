/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SnackbarProvider, useSnackbar } from 'notistack';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';

import {
  Observable,
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import routes, { renderRoutes } from './router';
import ScrollToTop from './utils/ScrollToTop';
import ThemeProvider from './theme/ThemeProvider';
import ConfigStore from './redux/strore/configStore';
import { login, logout } from './redux/actions/auth.action';
import AwsServices from './services/aws-congnito.service';

function App() {
  const history = createBrowserHistory();
  const { enqueueSnackbar } = useSnackbar();
  const reduxConfig = ConfigStore();
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_NADAM_GRAPHQL_API,
  });

  const getStoreState = () => {
    return JSON.parse(localStorage.state).auth;
  };
  const authLink = setContext(async (_, { headers }) => {
    const authState = getStoreState();
    const idToken =
      authState && authState.isAuthenticated ? authState.user.jwtToken : '';
    const token = idToken || '';
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  const showErrorNotification = (message = 'Session is expired.') => {
    enqueueSnackbar(message, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 5000,
    });
  };

  // eslint-disable-next-line consistent-return
  const errorLink = onError(({ networkError, operation, forward }: any) => {
    if (networkError.statusCode === 401) {
      const aws = new AwsServices();
      const authState = getStoreState();
      return new Observable((observer) => {
        aws
          .requestRefreshToken()
          .then((idToken) => {
            const state = authState.user;
            state.jwtToken = idToken.jwtToken;
            reduxConfig.dispatch(login(state));
            operation.setContext(({ headers = {} }: any) => {
              // eslint-disable-next-line no-param-reassign
              headers.Authorization = `Bearer ${idToken.jwtToken}`;
              return {
                headers,
              };
            });
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            // No refresh or client token available, we force user to login
            showErrorNotification();
            observer.error(error);
            ConfigStore().dispatch(logout());
          });
      });
    }
  });

  const apolloClient = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache({
      resultCaching: false,
    }),
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Router history={history}>
            <ScrollToTop />
            <CssBaseline />
            <Provider store={reduxConfig}>
              <ApolloProvider client={apolloClient}>
                {renderRoutes(routes)}
              </ApolloProvider>
            </Provider>
          </Router>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default () => {
  return (
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>
  );
};
