import { IShrutiBoxFile } from './type';

const s3Path = `${process.env.REACT_APP_S3_URL}`;
export const ShrutiBoxFiles: IShrutiBoxFile[] = [
  {
    fileName: 'A2',
    url: `${s3Path}A2.mp3`,
  },
  {
    fileName: 'E3',
    url: `${s3Path}E3.mp3`,
  },
  {
    fileName: 'F2',
    url: `${s3Path}F2.mp3`,
  },
  {
    fileName: 'F3',
    url: `${s3Path}F3.mp3`,
  },
  {
    fileName: 'Fsharp2',
    url: `${s3Path}Fsharp2.mp3`,
  },
  {
    fileName: 'Fsharp3',
    url: `${s3Path}Fsharp3.mp3`,
  },
  {
    fileName: 'G2',
    url: `${s3Path}G2.mp3`,
  },
  {
    fileName: 'G3',
    url: `${s3Path}G3.mp3`,
  },
];
