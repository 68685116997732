/* eslint-disable react/jsx-props-no-spreading */
import { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';
import { routeConfig } from './route-config';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
  id?: string;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<SuspenseLoader />}>
    <Switch>
      {routes.map((route) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={route.id}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    path: '/dashboard',
    guard: Authenticated,
    layout: SidebarLayout,
    id: 'dashboard',
    routes: [
      {
        exact: true,
        path: '/dashboard/class',
        component: lazy(() => import('src/content/pages/Classes/Index')),
        id: 'classes',
      },
      {
        exact: true,
        path: '/dashboard/class/details',
        component: lazy(() => import('src/content/pages/Classes/ClassDetails')),
        id: 'classDetails',
      },
      {
        exact: true,
        path: [
          '/dashboard/notebooks',
          routeConfig.VIEW_BOOK_FROM_COMPOSER.path,
        ],
        component: lazy(() => import('src/content/pages/Notebook/notebook')),
        id: 'notebook',
      },
      {
        exact: true,
        path: '/dashboard/messages',
        component: () => <Redirect to="/dashboard/messages/inbox" />,
        id: 'messages',
      },
      {
        exact: true,
        path: [
          '/dashboard/messages/tag/:labelTag/:messageCategory?',
          '/dashboard/messages/:categoryTag/:messageCategory?',
        ],
        component: lazy(() => import('src/content/pages/Messages')),
        id: 'messagesCategory',
      },

      {
        exact: true,
        path: '/dashboard/events',
        component: lazy(() => import('src/content/pages/Events/Event')),
        id: 'events',
      },
      {
        exact: true,
        path: '/dashboard/discography',
        component: lazy(() => import('src/content/pages/Discography')),
        id: 'discography',
      },
      {
        exact: true,
        path: '/dashboard/settings',
        component: lazy(() => import('src/content/pages/Setting')),
        id: 'settings',
      },
      {
        exact: true,
        path: '/dashboard/profile',
        component: lazy(() => import('src/content/pages/Profile')),
        id: 'profile',
      },
      {
        exact: true,
        path: '/dashboard/composer',
        component: lazy(() => import('src/content/pages/Composer/composer')),
        id: 'composer',
      },
      {
        exact: true,
        path: routeConfig.NOTEBOOk_DETAILS.path,
        component: lazy(
          () =>
            import(
              './content/pages/Notebook/components/notebook-details/notebook-details'
            ),
        ),
        id: 'notebookDetails',
      },
      {
        exact: true,
        path: '/dashboard/event/:id',
        component: lazy(() => import('src/content/pages/Events/EventDetails')),
        id: 'event details',
      },
      {
        exact: true,
        path: '/dashboard/homework',
        component: lazy(() => import('src/content/pages/Homework')),
        id: 'homework',
      },
      {
        exact: true,
        path: '/dashboard/payment/:id',
        component: lazy(() => import('src/content/pages/Payment')),
        id: 'payment',
      },
      {
        exact: true,
        path: '/dashboard/payment/history/:id',
        component: lazy(
          () => import('src/content/pages/Payment/PaymentHistory'),
        ),
        id: 'History',
      },
      {
        exact: true,
        path: '/dashboard/landing',
        component: lazy(() => import('src/content/pages/Landing')),
        id: 'Landing',
      },
      {
        component: () => <Redirect to="/status/404" />,
        id: '404',
      },
    ],
  },
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/dashboard/landing" />,
    id: 'root',
  },
  {
    exact: true,
    guard: Guest,
    path: '/login',
    component: lazy(() => import('src/content/pages/Auth/Login/Basic')),
    id: 'login',
  },
  {
    exact: true,
    guard: Guest,
    path: '/confirm-signup',
    component: lazy(
      () => import('src/content/pages/Auth/Register/ConfirmSignup'),
    ),
    id: 'cfmSignUp',
  },
  {
    exact: true,
    guard: Guest,
    path: '/register',
    component: lazy(() => import('src/content/pages/Auth/Register/Basic')),
    id: 'signUp',
  },
  {
    exact: true,
    path: '/recover-password',
    component: lazy(() => import('src/content/pages/Auth/RecoverPassword')),
    id: 'recoverPassword',
  },
  {
    exact: true,
    path: '/confirm-recover-password',
    component: lazy(
      () => import('src/content/pages/Auth/RecoverPassword/ResetPassword'),
    ),
    id: 'confirmRecoverPassword',
  },
  {
    exact: true,
    path: '/status/404',
    component: lazy(() => import('src/content/pages/Status/Status404')),
    id: '404Error',
  },
  {
    path: '*',
    layout: BaseLayout,
    id: 'common',
    routes: [
      {
        component: () => <Redirect to="/" />,
        id: 'baseLayout',
      },
    ],
  },
];

export default routes;
