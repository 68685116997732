import { useCallback, useState } from 'react';
import { IShrutiBoxFile, IShrutiboxState } from './type';

export const useShrutiBox = () => {
  const [state, setState] = useState<IShrutiboxState | null>({
    currentPlayingAudio: null,
    isPopupOpen: false,
  });

  const onClickHandle = useCallback((file: IShrutiBoxFile) => {
    setState((preState) => ({ ...preState, currentPlayingAudio: file }));
  }, []);

  const handlePopupClose = useCallback(
    () => setState((preState) => ({ ...preState, isPopupOpen: false })),
    [],
  );
  const handlePopupOpen = useCallback(
    () => setState((preState) => ({ ...preState, isPopupOpen: true })),
    [],
  );
  return {
    currentPlayingAudio: state.currentPlayingAudio,
    isPopupOpen: state.isPopupOpen,
    onClickHandle,
    handlePopupClose,
    handlePopupOpen,
  };
};
