import { subHours } from 'date-fns';
import type { PaymentHistory } from 'src/models/payment';

const paymentHistoryMockData: PaymentHistory[] = [
  {
    id: '1',
    number: '5262',
    issuedDate: subHours(new Date(), 18).getTime(),
    dueDate: subHours(new Date(), 15).getTime(),
    clientName: 'Nlounge',
    clientAvatar: '/static/images/avatars/1.jpg',
    amount: 1497,
    currency: '₹',
    status: 'pending',
    mode: 'online',
  },
  {
    id: '2',
    number: '6739',
    issuedDate: subHours(new Date(), 21).getTime(),
    dueDate: subHours(new Date(), 18).getTime(),
    clientName: 'Thoughtmix',
    clientAvatar: '/static/images/avatars/2.jpg',
    amount: 5689,
    currency: '₹',
    status: 'completed',
    mode: 'online',
  },
  {
    id: '3',
    number: '7849',
    issuedDate: subHours(new Date(), 44).getTime(),
    dueDate: subHours(new Date(), 21).getTime(),
    clientName: 'Oyoba',
    clientAvatar: '/static/images/avatars/3.jpg',
    amount: 611,
    currency: '₹',
    status: 'pending',
    mode: 'cash',
  },
  {
    id: '4',
    number: '6839',
    issuedDate: subHours(new Date(), 36).getTime(),
    dueDate: subHours(new Date(), 24).getTime(),
    clientName: 'Twimm',
    clientAvatar: '/static/images/avatars/4.jpg',
    amount: 8792,
    currency: '₹',
    status: 'completed',
    mode: 'cash',
  },
  {
    id: '5',
    number: '7684',
    issuedDate: subHours(new Date(), 44).getTime(),
    dueDate: subHours(new Date(), 27).getTime(),
    clientName: 'Meembee',
    clientAvatar: '/static/images/avatars/5.jpg',
    amount: 3947,
    currency: '₹',
    status: 'pending',
    mode: 'check',
  },
  {
    id: '6',
    number: '7837',
    issuedDate: subHours(new Date(), 65).getTime(),
    dueDate: subHours(new Date(), 32).getTime(),
    clientName: 'Trudoo',
    clientAvatar: '/static/images/avatars/1.jpg',
    amount: 5133,
    currency: '₹',
    status: 'completed',
    mode: 'check',
  },
  {
    id: '7',
    number: '6831',
    issuedDate: subHours(new Date(), 44).getTime(),
    dueDate: subHours(new Date(), 35).getTime(),
    clientName: 'Buzzdog',
    clientAvatar: '/static/images/avatars/2.jpg',
    amount: 7255,
    currency: '₹',
    status: 'completed',
    mode: 'online',
  },
  {
    id: '8',
    number: '8936',
    issuedDate: subHours(new Date(), 43).getTime(),
    dueDate: subHours(new Date(), 41).getTime(),
    clientName: 'Realcube',
    clientAvatar: '/static/images/avatars/3.jpg',
    amount: 6091,
    currency: '₹',
    status: 'pending',
    mode: 'online',
  },
  {
    id: '9',
    number: '9683',
    issuedDate: subHours(new Date(), 76).getTime(),
    dueDate: subHours(new Date(), 51).getTime(),
    clientName: 'Zoomzone',
    clientAvatar: '/static/images/avatars/4.jpg',
    amount: 7087,
    currency: '₹',
    status: 'completed',
    mode: 'online',
  },
  {
    id: '10',
    number: '3798',
    issuedDate: subHours(new Date(), 87).getTime(),
    dueDate: subHours(new Date(), 65).getTime(),
    clientName: 'Eabox',
    clientAvatar: '/static/images/avatars/5.jpg',
    amount: 3999,
    currency: '₹',
    status: 'completed',
    mode: 'online',
  },
  {
    id: '11',
    number: '8982',
    issuedDate: subHours(new Date(), 78).getTime(),
    dueDate: subHours(new Date(), 76).getTime(),
    clientName: 'Ozu',
    clientAvatar: '/static/images/avatars/1.jpg',
    amount: 5867,
    currency: '₹',
    status: 'pending',
    mode: 'online',
  },
  {
    id: '12',
    number: '7891',
    issuedDate: subHours(new Date(), 91).getTime(),
    dueDate: subHours(new Date(), 87).getTime(),
    clientName: 'Fivespan',
    clientAvatar: '/static/images/avatars/2.jpg',
    amount: 6337,
    currency: '₹',
    status: 'completed',
    mode: 'online',
  },
  {
    id: '13',
    number: '7982',
    issuedDate: subHours(new Date(), 102).getTime(),
    dueDate: subHours(new Date(), 91).getTime(),
    clientName: 'Twitternation',
    clientAvatar: '/static/images/avatars/3.jpg',
    amount: 6712,
    currency: '₹',
    status: 'pending',
    mode: 'online',
  },
  {
    id: '14',
    number: '7092',
    issuedDate: subHours(new Date(), 122).getTime(),
    dueDate: subHours(new Date(), 94).getTime(),
    clientName: 'Rhyzio',
    clientAvatar: '/static/images/avatars/4.jpg',
    amount: 6004,
    currency: '₹',
    status: 'pending',
    mode: 'online',
  },
  {
    id: '15',
    number: '5923',
    issuedDate: subHours(new Date(), 196).getTime(),
    dueDate: subHours(new Date(), 99).getTime(),
    clientName: 'Trudeo',
    clientAvatar: '/static/images/avatars/5.jpg',
    amount: 5188,
    currency: '₹',
    status: 'completed',
    mode: 'online',
  },
];

export { paymentHistoryMockData };
