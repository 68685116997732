const routePrefix = 'dashboard';
const composerUrl = 'composer';
const notebookUrl = 'notebooks';
const bookUrl = 'notebook';
export const routeConfig = {
  COMPOSER: {
    path: `/${routePrefix}/${composerUrl}`,
  },
  NOTEBOOK: {
    path: `/${routePrefix}/${notebookUrl}`,
  },
  NOTEBOOk_DETAILS: {
    path: `/${routePrefix}/${notebookUrl}/details`,
  },
  VIEW_BOOK_FROM_COMPOSER: {
    path: `/${routePrefix}/${composerUrl}/${bookUrl}`,
  },
};
