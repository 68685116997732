import { useTranslation } from 'react-i18next';
import usFlag from 'country-flag-icons/3x2/US.svg';
import {
  Box,
  List,
  ListItem,
  Divider,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import internationalization from 'src/i18n/i18n';

const SectionHeading = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`,
);

const ImageWrapper = experimentalStyled('img')(
  ({ theme }) => `
        margin-right: ${theme.spacing(1)};
        width: 24px;
`,
);

function LanguageToggle() {
  const { i18n } = useTranslation();
  const { t }: { t: any } = useTranslation();
  const getLanguage = i18n.language;

  const switchLanguage = ({ lng }: { lng: any }) => {
    internationalization.changeLanguage(lng);
  };

  return (
    <Box sx={{ minWidth: 250 }}>
      <SectionHeading variant="body2" color="text.primary">
        {t('Language Switcher')}
      </SectionHeading>
      <List sx={{ p: 2 }} component="nav">
        <ListItem
          className={getLanguage === 'en' ? 'active' : ''}
          button
          onClick={() => switchLanguage({ lng: 'en' })}
        >
          <ImageWrapper alt="USA" src={usFlag} />
          <ListItemText primary="USA" />
        </ListItem>
      </List>
      <Divider />
    </Box>
  );
}

export default LanguageToggle;
