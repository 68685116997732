/* eslint-disable react/require-default-props */
import { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

interface GuestProps {
  children?: ReactNode;
}

const Guest: FC<GuestProps> = ({ children }) => {
  const { isAuthenticated } = useSelector((state: any) => state.auth);

  if (isAuthenticated) {
    return <Redirect to="/preview/dashboards/analytics" />;
  }

  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node,
};

export default Guest;
