import { Box, Hidden, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const LogoWrapper = experimentalStyled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`,
);
const LogoImg = experimentalStyled(Box)(
  () => `
        height:50px;
        width:50px;
        background:url('/static/images/logo/Logo.png');
        background-size:cover;
        border-radius:5%;
`,
);

const LogoTextWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        padding-left: ${theme.spacing(2)};
        text-transform: uppercase;
`,
);

function Logo() {
  const { t }: { t: any } = useTranslation();

  return (
    <LogoWrapper to="/overview">
      <LogoImg />

      <Hidden smDown>
        <LogoTextWrapper display="flex" alignItems="center">
          <Typography variant="h4">{t('nadam')}</Typography>
        </LogoTextWrapper>
      </Hidden>
    </LogoWrapper>
  );
}

export default Logo;
