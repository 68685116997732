interface AuthState {
  isAuthenticated: boolean;
  user: any | null;
}

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: any;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: any;
  };
};

type UpdateUserAction = {
  type: 'UPDATE_USER_IDENT';
  payload: {
    userProfile: any;
  };
};

type Action = LoginAction | LogoutAction | RegisterAction | UpdateUserAction;
const initialAuthState = {
  isAuthenticated: false,
  user: null,
};
const reducer = (
  state: AuthState = initialAuthState,
  action: Action,
): AuthState => {
  switch (action.type) {
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'UPDATE_USER_IDENT': {
      const { userProfile } = action.payload;
      return {
        ...state,
        user: { ...state.user, ...userProfile },
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default reducer;
