/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

interface AuthenticatedProps {
  children?: ReactNode;
}

type stateType = { auth: any };
const Authenticated: FC<AuthenticatedProps> = ({ children }) => {
  const { isAuthenticated } = useSelector((state: stateType) => state.auth);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node,
};

export default Authenticated;
