import { FC, useRef } from 'react';
import {
  Popover,
  Typography,
  Stack,
  Divider,
  Chip,
  Box,
  Tooltip,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { useTranslation } from 'react-i18next';
import { Piano } from '@material-ui/icons';

import { useShrutiBox } from './shruti-box-hook';
import { ShrutiBoxFiles } from './constants';

const ShrutiBoxButton = experimentalStyled(Box)(
  ({ theme }) => `
          position: fixed;
          right: ${theme.spacing(4)};
          bottom: ${theme.spacing(4)};
          
          &::before {
              width: 30px;
              height: 30px;
              content: ' ';
              position: absolute;
              border-radius: 100px;
              left: 13px;
              top: 13px;
              background: ${theme.colors.primary.main};
              animation: ripple 1s infinite;
              transition: ${theme.transitions.create(['all'])};
          }

          .MuiSvgIcon-root {
            animation: pulse 1s infinite;
            transition: ${theme.transitions.create(['all'])};
          }
  `,
);

const ShrutiBoxWrapper = experimentalStyled(Box)(
  ({ theme }) => `
          padding: ${theme.spacing(2)};
          min-width: 220px;
  `,
);

export const ShrutiBox: FC = () => {
  const { t }: { t: any } = useTranslation();
  const ref = useRef<any>(null);
  const {
    currentPlayingAudio,
    handlePopupClose,
    handlePopupOpen,
    isPopupOpen,
    onClickHandle,
  } = useShrutiBox();
  return (
    <ShrutiBoxButton>
      <Tooltip arrow title={t('Shruti Box')}>
        <Fab
          ref={ref}
          onClick={handlePopupOpen}
          color="primary"
          aria-label="add"
        >
          <Piano />
        </Fab>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handlePopupClose}
        open={isPopupOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box p={2}>
          <Box>
            <Box
              pb={2}
              textAlign="center"
              sx={{
                minWidth: 0,
              }}
            >
              <Typography
                variant="h4"
                mb={2}
                color="text.secondary"
                fontWeight={500}
              >
                SHRUTI BOX FROM NADAM
              </Typography>
              <Typography noWrap>
                <b>{currentPlayingAudio?.fileName ?? 'No audio playing'}</b>
              </Typography>
            </Box>
            {
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <audio controls loop src={currentPlayingAudio?.url} />
            }
          </Box>
        </Box>
        <Divider />
        <Stack alignItems="center">
          <ShrutiBoxWrapper>
            <Stack alignItems="center" spacing={2}>
              {ShrutiBoxFiles.map((file) => (
                <Chip
                  key={file.fileName}
                  label={file.fileName}
                  clickable
                  color="primary"
                  variant="outlined"
                  onClick={() => onClickHandle(file)}
                />
              ))}
            </Stack>
          </ShrutiBoxWrapper>
        </Stack>
      </Popover>
    </ShrutiBoxButton>
  );
};
