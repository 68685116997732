const enJSON = {
  confirmVerificationCode: 'Confirm Verification Code',
  codeRequired: 'Code is required',
  enterCode: 'Enter Code',
  confirm: 'Confirm',
  recoverPassword: 'Recover Password',
  loginWithGoogle: 'Login with Google',
  confirmCode: 'Confirm Code',
  newPassword: 'New Password',
  resetPassword: 'Reset Password',
  sideMenuClasses: 'Classes',
  sideMenuNotebook: 'Notebooks',
  sideMenuMessage: 'Messages',
  sideMenuEvent: 'Events',
  sideMenuDiscography: 'Discography',
  sideMenuSettings: 'Settings',
  sideMenuDashboard: 'Dashboards',
  sideMenuComposer: 'Composer',
  soundCloud: 'Sound Cloud',
  personalInformation: 'Personal Information',
  paymentHistory: 'Payment History',
  timelines: 'Timelines',
  events: 'Events',
  classes: 'Classes',
  progress: 'Progress',
  nadamInc: 'Nadam Inc',
  editProfile: 'Edit Profile',
  payNow: 'Pay Now',
  teacherTeachClass: 'These are the classes you are teaching',
  studentTeachClass: 'These are the classes you are attending',
  edit: 'Edit',
  join: 'Join Now',
  start: 'Start Now',
  classInfo: 'Class Info',
  className: 'Class Name',
  teacherName: 'Teacher Name',
  classLevel: 'Class Level',
  currentLesson: 'Current Lesson',
  classStudent: 'Class Students',
  classCollection: 'Class Collection',
  addStudent: 'Add Student',
  addLesson: 'Add Lesson',
  classSchedule: 'Class Schedule',
  nextClass: 'Next Class',
  classDuration: 'Class Duration',
  classType: 'Class Type',
  courseDuration: 'Course Duration',
  courseProgress: 'Course Progress',
  fee: 'Fee',
  paymentSchedule: 'Payment Schedule',
  paymentMethod: 'Payment Method',
  paymentSetting: 'Payment Settings',
  remove: 'Remove',
  invite: 'Send Invitation',
  add: 'Add',
  author: 'Author',
  chapter: 'Chapter',
  noteBooks: 'Notebooks',
  textBooks: 'Textbooks',
  type: 'Type',
  watchVideo: 'Watch Video',
  one: 'One',
  two: 'Two',
  three: 'Three',
  kalam: 'Kalam',
  lesson: 'Lesson',
  talam: 'Talam',
  ragam: 'Ragam',
  composition: 'Composition',
  record: 'Record',
  talamPlaceHolder: 'Search by tala, jati, anga and length',
  ragamPlaceHolder: 'Search by name',
  allEvents: 'All Events',
  activeEvents: 'Active Events',
  upcomingEvents: 'Upcoming Events',
  eventRegistration: 'Registration',
  upcoming: 'Upcoming',
  dateTime: 'Date and Time',
  location: 'Location',
  organizer: 'Organizer',
  eventType: 'Event Type',
  performingArtists: 'Performing Artists',
  entry: 'Entry',
  goBack: 'Go Back',
  createEvent: 'Create Event',
  eventTitle: 'Event Title',
  eventTitlePlaceholder: 'Enter event name..',
  eventDescription: 'Event Description',
  eventDescriptionPlaceholder: 'Event event description..',
  eventStartDate: 'Event Start Date',
  eventEndDate: 'Event End Date',
  eventOrganizer: 'Event Organizer',
  eventOrganizerPlaceholder: 'Enter event organizer..',
  eventLocation: 'Event Location',
  eventLocationPlaceholder: 'Enter location..',
  eventEntry: 'Event Entry',
  uploadEventPhoto: 'Upload Event Photo',
  close: 'Close',
  eventArtists: 'Performing artists',
  eventArtistsPlaceholder: 'Enter artists name..',
  addArtistByComaSeparator: 'Use coma ( , ) separator for add multiple artists',
  eventTask: 'List of compositions to be performed',
  eventTaskPlaceholder: '1 Composition1\n2 Composition2\n3 Composition3',
  payments: 'Payments',
  sendMessage: 'Send New Message',
  messageSubject: 'Subject',
  messageBody: 'Message',
  sendingTo: 'Sending To',
  recentDiscography: 'Recent Discography',
  upload: 'Upload',
  filter: 'Filter',
  addLink: 'Add Link',
  share: 'Share',
  bookmark: 'Bookmark',
  favorite: 'Favorite',
  createDiscography: 'Create Discography',
  postDate: 'Date of Post',
  tagPeople: 'Tag People',
  uploadMedia: 'Upload Media',
  discographyTitle: 'Enter name of discography.',
  title: 'Title',
  description: 'Description',
  discographyDescription: 'Enter discography description.',
  discographyLocation: 'Enter location.',
  selectLesson: 'Select Lesson',
  externalMediaUrl: 'External Media Url',
  discographyExternalUrl: 'Enter YouTube,SoundCloud and others media url.',
  submit: 'Submit',
  privacySettings: 'Privacy Settings',
  homework: 'Homework',
  homeworkPageBrowserTitle: 'Homework ',
  submitHomework: 'Create Homework',
  all: 'All',
  notReviewed: 'Not Reviewed',
  reviewComplete: 'Review Completed',
  newComment: 'New Comment',
  descriptionField: 'Description',
  createHomeworkModalTitle: 'Create Homework',
  createHomeworkModalSubtitle: 'Description Write here',
  viewBtnTooltips: 'View',
  commentBtnTooltips: 'Comments',
  markAsDoneBtnTooltips: 'Mark As Completed',
  studentNameTableField: 'Name',
  submissionDateTableField: 'Submitted On',
  statusTableField: 'Status',
  actionTableField: 'Actions',
  lastReviewedByTableField: 'Last Reviewed By',
  btnUpdate: 'Update',
  btnApprove: 'Approve',
  updateHomework: 'Update Homework',
  'field.required': 'Field is required',
  inPerson: 'In-Person',
  'field.selectStudent': 'SELECT STUDENTS',
  'field.selectLocation': 'SELECT LOCATION',
  'field.selectClassDuration': 'SELECT CLASS DURATION',
  'field.selectDayOfMonth.error.invalid': 'Enter valid date',
  'field.selectMonths.error.invalid':
    'Enter valid month. value should be 1 to 3',
  'class.emptyStudentList': 'No Student Found',
  minutes: 'Minutes',
  'field.name': 'Name',
  'field.author': 'Author',
  'field.reference': 'Reference',
  'field.ISBN': 'ISBN',
  'field.version': 'Version',
  createNotebook: 'Create Notebook',
  createTextbook: 'Create Textbook',
  createComposition: 'Create New Composition',
  composer: 'Composer',
  saveDraft: 'Save Draft',
  publishNow: 'Publish Now',
  resultNotFound: 'You have no item to display',
  'error.noSelectedBook':
    'Please select Notebook/Textbook for view composition',
  clickHere: 'click here',
  searchByName: 'Search by name',
  recordsSuccessfullyUpdated: 'Records successfully updated',
  invalidRecordTitle: 'Invalid Record Error',
  invalidRecordDesc: 'Active record is invalid',
  wantToDelete: 'Are you sure want to delete this item ?',
  yesDeleteBtn: 'Yes Delete',
  kaalam: 'Kaalam',
  dateOfPublished: 'Date of Published',
  numOfRecords: 'Records',
  notebook: 'Notebook',
  textbook: 'Textbook',
  selectTextbookOrNotebook: 'Select textbook/notebook',
  compositions: 'Compositions',
  selectAll: 'Select All',
  loadingCompositions: 'Loading Compositions .....',
  'lesson.emptyList': 'No lesson found',
  editComposition: 'Edit Composition',
  createdAt: 'Created',
  lastModified: 'Last Modified',
  numComposition: 'Compositions',
  notes: 'Notes',
  publisher: 'Publisher',
  additionalNotes: 'Additional Notes',
};

export default enJSON;
