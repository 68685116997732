/* eslint-disable no-throw-literal */
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Amplify, Auth } from 'aws-amplify';

class AwsServices {
  constructor() {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        oauth: {
          domain: process.env.REACT_APP_AUTH_DOMAIN,
          scope: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: `${process.env.REACT_APP_PUBLIC_URL}/login`,
          redirectSignOut: `${process.env.REACT_APP_PUBLIC_URL}/login`,
          responseType: 'code',
        },
      },
    });
  }

  signIn = async (username, password) => {
    try {
      const userData = await Auth.signIn(username.toLowerCase(), password);
      return {
        status: 'OK',
        userData,
      };
    } catch (error) {
      return handleError(error);
    }
  };

  confirmSignUp = async (username: string, code: string) => {
    try {
      const data = await Auth.confirmSignUp(username, code);
      return data;
    } catch (error) {
      return handleError(error);
    }
  };

  // Send confirmation code to user's email
  forgotPassword = async (username: string) => {
    try {
      const data = await Auth.forgotPassword(username);
      return data;
    } catch (error) {
      return handleError(error);
    }
  };

  // Social Login
  socialLogin = async () => {
    try {
      const data = await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
      return data;
    } catch (error) {
      return handleError(error);
    }
  };

  // Collect confirmation code and new password, then
  forgotPasswordConfirm = async (
    username: string,
    code: string,
    newPassword: string,
  ) => {
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
      return data;
    } catch (error) {
      return handleError(error);
    }
  };

  signUp = async (email: string, username: string, password: string) => {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name: username,
        },
      });
      return user;
    } catch (error) {
      return handleError(error);
    }
  };

  newPasswordSubmit = async (user, password) => {
    try {
      await Auth.completeNewPassword(user, password, []);
      return {
        status: 'OK',
      };
    } catch (error) {
      return handleError(error);
    }
  };

  // socialLogin = async (provider) => {
  //     await Auth.federatedSignIn({ provider: 'Google' });
  // }
  logout = async () => {
    try {
      await Auth.signOut();
      return { status: 'OK' };
    } catch (error) {
      return handleError(error);
    }
  };

  resendConfirmationCode = async (username) => {
    try {
      await Auth.resendSignUp(username);
      return { status: 'OK' };
    } catch (error) {
      return handleError(error);
    }
  };

  requestRefreshToken = async () => {
    const currentSession: any = await Auth.currentSession();
    return currentSession.idToken;
  };
}

const handleError = (error: any) => {
  throw {
    status: 'ERROR',
    code: error?.code,
    name: error?.name,
    message: error?.message,
  };
};

export default AwsServices;
