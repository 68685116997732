import { ReactNode } from 'react';
import {
  Event,
  Forum,
  Note,
  Schedule,
  Settings,
  Subscriptions,
  MusicNote,
} from '@material-ui/icons';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'sideMenuDashboard',
    items: [
      {
        name: 'sideMenuClasses',
        icon: Schedule,
        link: '/dashboard/class',
      },
      {
        name: 'sideMenuNotebook',
        icon: Note,
        link: '/dashboard/notebooks',
      },
      {
        name: 'sideMenuMessage',
        icon: Forum,
        link: '/dashboard/messages',
      },
      {
        name: 'sideMenuEvent',
        icon: Event,
        link: '/dashboard/events',
      },
      {
        name: 'sideMenuDiscography',
        icon: Subscriptions,
        link: '/dashboard/discography',
      },
      {
        name: 'sideMenuComposer',
        icon: MusicNote,
        link: '/dashboard/composer',
      },
    ],
  },
  {
    heading: 'sideMenuSettings',
    items: [
      {
        name: 'sideMenuSettings',
        icon: Settings,
        link: '/dashboard/settings',
      },
    ],
  },
];

export default menuItems;
