export const login = (user) => {
  return {
    type: 'LOGIN',
    payload: {
      user,
    },
  };
};
export const logout = () => ({
  type: 'LOGOUT',
});
export const updateUserProfile = (userProfile) => ({
  type: 'UPDATE_USER_IDENT',
  payload: {
    userProfile,
  },
});
